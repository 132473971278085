<script setup>
import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from 'vue-router';
import imgError from "@/assets/paypay/error.png";

let router = useRouter()
let back = () => {
    //后退
    router.push('/store?order=1')
}
</script>

<template>
  <div class="paypay_success_box">
    <div class="paypay_box">
      <el-image :src="imgError"></el-image>
      <div class="text_box">
        <div class="text">Unfortunately</div>
 
        <div class="text">an error was encountered and the payment failed!</div>
      </div>
      <div class="btn_box">
        <el-button class="continueShopping" @click="back">Back</el-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.paypay_success_box {
  display: flex;
  justify-content: center;
  .paypay_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 590px;
    // height: 0px;
    // background: red;
    margin-top: 180px;
    .btn_box {
      margin-top: 60px;
      .continueShopping {
        height: 40px;
        background: #ff2379;
        border-radius: 3px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
      }
    }
    .text_box {
      margin-top: 20px;
      text-align: center;
      .text {
        font-size: 23px;
        font-weight: 600;
        color: #000000;
        line-height: 33px;
      }
    }
  }
}
</style>
